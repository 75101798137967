<template>
  <v-card>
    <v-toolbar :color="mainColor" dark flat>
      <v-toolbar-title>サインイン</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form @submit.prevent="doSubmit">
        <v-text-field v-model="username" label="ユーザー名" />
        <v-text-field
          v-model="password"
          label="パスワード"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
        />
        <v-text-field
          v-if="requireNewPassword"
          v-model="newPassword"
          label="新しいパスワード ※8文字以上、小・大文字を含む英数字、記号"
          :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showNewPassword ? 'text' : 'password'"
          @click:append="showNewPassword = !showNewPassword"
        />
      </v-form>
      <p>
        パスワードを忘れた場合
        <router-link to="/auth/forgot-password">
          パスワードのリセット
        </router-link>
      </p>
      <v-alert
        v-if="message !== '' && messageType != ''"
        :type="messageType"
        outlined
        >{{ message }}</v-alert
      >
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="!requireNewPassword"
        :color="mainColor"
        text
        @click="doSignIn"
        >サインイン</v-btn
      >
      <v-btn v-else :color="mainColor" text @click="doRequireNewPassword"
        >変更</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { Auth, I18n } from 'aws-amplify'
import { MAIN_COLOR } from '@/constants'

const newPasswordRequired = 'NEW_PASSWORD_REQUIRED'

export default {
  name: 'AuthSignIn',
  data() {
    return {
      mainColor: MAIN_COLOR,
      username: '',
      password: '',
      newPassword: '',
      email: '',
      requireNewPassword: false,
      authData: null,
      message: '',
      messageType: '',
      showPassword: false,
      showNewPassword: false
    }
  },
  methods: {
    async doSignIn() {
      this.message = ''
      this.messageType = ''
      try {
        const res = await Auth.signIn(this.username, this.password)
        if (res.challengeName === newPasswordRequired) {
          this.requireNewPassword = true
          this.authData = res
        }
        this.$router.push('/home')
      } catch (e) {
        this.handleError(e)
      }
    },
    async doRequireNewPassword() {
      this.message = ''
      this.messageType = ''
      try {
        const res = await Auth.completeNewPassword(
          this.authData,
          this.newPassword
        )
        this.password = ''
        this.messageType = 'success'
        this.message = 'パスワードを変更しました'
        this.requireNewPassword = false
      } catch (e) {
        this.handleError(e)
      }
    },
    doSubmit() {
      this.doSignIn()
    },
    handleError(e) {
      switch (e.name) {
        case 'AuthError':
          this.messageType = 'error'
          this.message = I18n.get(e.message)
          break
        case 'NotAuthorizedException':
        case 'InvalidParameterException':
        case 'UserNotFoundException':
          console.log(e.message)
          this.messageType = 'error'
          this.message = 'ユーザー名またはパスワードが違います'
          break
        case 'InvalidPasswordException':
          console.log(e.message)
          this.messageType = 'error'
          this.message = I18n.get('password-error')
          break
        default:
          console.log(e.message)
          this.messageType = 'error'
          this.message = I18n.get(e.message)
          break
      }
    }
  }
}
</script>
